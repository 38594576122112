<template>
  <div>
    <base-header>
      <div style="display: inline-block; width: 100%;">
        <div style="float: left;">
          <h1 class="text-white">Dashboard</h1>
        </div>
        <div style="float: right; margin-top: 5px;"> 
          <span style="color: white; font-size:medium; ">Page refresh time: {{ updateTime }} </span>
        </div>
      </div>
      <b-row class="m-1" :no-gutters=true>
        <b-col cols="12" md="8" lg="9" class="rounded bg-white mb-4">
          <el-carousel 
              indicator-position="inside" 
              :interval="5000"
              trigger="click"
            >
              <el-carousel-item class="p-4" v-for="news in newsData.filter(f=>f.content.length > 20)" :key="news.title">
                <b-row align-v="center">
                  <b-col cols="1" md="4" lg="3">
                    <img 
                      loading="lazy" 
                      :src="news.imgUrl" 
                      alt="your insurance annoucement" 
                      width="150" 
                      height="160"
                    />
                  </b-col>
                  <b-col cols="11" md="8" lg="9">
                    <h2 class="text-muted mb-4">{{ news.title }}</h2>
                    <div class="text-wrap" v-html="news.content"></div>
                  </b-col>
                </b-row>
              </el-carousel-item>
          </el-carousel>
        </b-col>
        <b-col cols="12" md="4" lg="3" class="pl-4">
          <div  v-for="card in cardData" :key="card.title" class="mb-3">
            <div class="bg-white rounded p-2">
              <b-row class="mb-4">
                <b-col cols="10" xl="8" lg="9">
                  <h4 class="text-muted font-weight-bolder">
                    {{ card.title.toUpperCase() }}
                  </h4>
                  <strong style="font-size: 31px;">{{ card.number }}</strong>
                </b-col>
                <b-col cols="2" xl="3" lg="3" align-self="end">
                  <i :class="[card.icon, 'text-danger icon']" aria-hidden="true"></i>
                </b-col>
              </b-row>
              <div>
                <span v-if="card.percentLastMonth >= 0" class="text-success style-span">
                  <b-icon icon="arrow-up"></b-icon>{{ formatNumber(card.percentLastMonth) }}% Since last month
                </span>
                <span v-else-if="card.percentLastMonth < 0" class="text-red style-span">
                  <b-icon icon="arrow-down"></b-icon>{{ formatNumber(card.percentLastMonth) }}% Since last month
                </span>
                <span v-else class="text-gray style-span">
                  No update
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="pb-6">
      <b-row align-h="around" class="pb-5">
      <!-- <b-col v-for="card in cardData" :key="card.title" cols="12" md="6" lg="3" class="p-3">
        <div class="bg-white rounded p-3">
          <b-row class="mb-3">
            <b-col cols="10" xl="8" lg="9">
              <h4 class="text-muted font-weight-bolder">
                {{ card.title.toUpperCase() }}
              </h4>
              <strong>{{ card.number }}</strong>
            </b-col>
            <b-col cols="2" xl="3" lg="3">
              <i :class="[card.icon, 'text-danger icon']" aria-hidden="true"></i>
            </b-col>
          </b-row>
          <div>
            <span v-if="card.percentLastMonth >= 0" class="text-success style-span">
              <b-icon icon="arrow-up"></b-icon>{{ formatNumber(card.percentLastMonth) }}% Since last month
            </span>
            <span v-else-if="card.percentLastMonth < 0" class="text-red style-span">
              <b-icon icon="arrow-down"></b-icon>{{ formatNumber(card.percentLastMonth) }}% Since last month
            </span>
            <span v-else class="text-gray style-span">
              No update
            </span>
          </div>
        </div>
      </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { Carousel, CarouselItem } from "element-ui"
import { reportFunctions } from "../../helpers/reportFunctions";
import { appFunctions } from "../../helpers/appFunctions";
import moment from "moment";
import { GlobalStore } from "../../globalStore";

export default {
    name: "dashboard-header",
    data() {
        return {
            newsData: [
              {
                title: "Your Insurance",
                content: "Your Insurance is an insurtech delivering technology, product design, advisory and marketing business solutions to the insurance and finance sectors. We design innovative technology services to enhance business value and growth for our partners.",
                imgUrl: "https://yourinsurancehq.com.au/wp-content/uploads/2021/08/About-Us_Header-Image_Desktop.png",

              },
              {
                title: "Light Commercial and Fleets",
                content: "Mercurien Insurance offers light commercial motor insurance for individual vehicles and fleets (of up to 4.5 tonnes) with 25% of the quote allowed to be over a 4.5 tonne allowance. Commissions are 10% of premium.",
                imgUrl: "https://yourinsurancehq.com.au/wp-content/uploads/2021/08/Homepage_Product-Image_Car-Retail_Image_Desktop.png",

              },
              {
                title: "Announcements",
                content: "",
                imgUrl: "https://yourinsurancehq.com.au/wp-content/uploads/2021/08/Partnership_How-we-work-with-you_Image_Desktop.png",

              }
            ],
            cardData: [
                {
                    title: "total quotes",
                    number: "0",
                    icon: "fa fa-plus-circle",
                    key: "totalQuotes"
                },
                {
                    title: "total customers",
                    number: "0",
                    icon: "fa fa-user-circle",
                    key: "totalCustomer"
                },
                // {
                //     title: "total brokers",
                //     number: "0",
                //     icon: "fa fa-info-circle",
                //     key: "totalBroker"
                // },
                // {
                //     title: "total claims",
                //     number: "0",
                //     icon: "fa fa-check-circle",
                //     key: "totalClaim"
                // },
            ],
        };
    },
    computed: {
        updateTime() {
            const tomorrow = moment().add(1, 'days');
            tomorrow.set('hour', 7);
            tomorrow.set('minute', 5);
            tomorrow.set('second', 0);
            return tomorrow.utc();
        }
    },
    methods: {
        formatNumber(number) {
            return number.replace('-', '');
        },
        checkBrokerOrganization() {
          const isInsuranceBroker = GlobalStore.brokerObj.parentOrg.is_insurance_broker;
          // The VIBS announcement should only be visible to Finance brokers and not Insurance brokers.
          if ( !isInsuranceBroker ) {
            this.newsData.push(
              {
                title: "Virtual Insurance Broking Service",
                content: "Our new Virtual Insurance Broking Service is a comparison style approach presenting multiple quotes for your client within 24-48 hours. You will be able to see the details under Quotes and Policies in the Portal.  You can quote Motor, Mobile Plant and Equipment and Other Assets under the Virtual Insurance Broking Services link.",
                imgUrl: "https://yourinsurancehq.com.au/wp-content/uploads/2021/08/Homepage_Product-Image_Plant-and-Equipment_Image_Desktop.png",

              },
            )
          }
        }
    },
    mounted() {
        this.checkBrokerOrganization()
        reportFunctions.reportTotal("", {})
            .then(resp => {
            this.cardData.forEach(element => {
                element.number = resp[element.key];
                if (element.key === 'totalQuotes') {
                    if (resp.totalQuotesByLastMonth != 0) {
                        element.percentLastMonth = (((resp.totalQuotesByMonth / resp.totalQuotesByLastMonth) - 1) * 100).toFixed(2);
                    }
                }
                if (element.key === 'totalCustomer') {
                    if (resp.totalCustomerByLastMonth != 0) {
                        element.percentLastMonth = (((resp.totalCustomerByMonth / resp.totalCustomerByLastMonth) - 1) * 100).toFixed(2);
                    }
                }
            });
        })
            .catch(error => {
            console.log("error:", error);
        })
            .finally(() => {
        });

        appFunctions
        .crud_contentTemplate("list")
        .then(resp => {
          var content = resp.data.find(f=> f.content_template_id == "announcements-template").content_template_html;
          this.newsData[2].content = content;
        });
    },
    components: { 
      [Carousel.name]: Carousel, 
      [CarouselItem.name]: CarouselItem
    }
};
</script>

<style scoped>
.text-danger {
  font-size: 2.5em;
}
.text-red {
  color: red;
}
.text-gray {
  color: gray;
}

@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .style-span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1355px) {
  .style-span {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1355px) {
  .icon {
    margin-left: 10px;
    font-size: 2.3em
  }
  img {
    width: 180px;
    height: 190px;
  }
}
</style>
