<template>
    <div>
        <div v-for="(faq, index) in faqItems" :key="index">
            <a :id="'anch_answer' + faq.id">
                <div class="faq">
                    <p class="question" @click="toggleAnswer(faq)">
                        {{ faq.q }}
                        <span class="toggleIcon">
                            {{ faq.isOpen ? "-" : "＋" }}
                        </span>
                    </p>
                    <p class="answer" :ref="'answer' + faq.id" :id="'answer' + faq.id" v-html="faq.a"></p>


                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mode: String
    },
    data() {
        return {
            password1: "",
            faqItems: [
                {
                    id: 1,
                    q: "Who is Your Insurance?",
                    a: `<p>Your Insurance is an insurtech offering technology,
                        advisory, and marketing solutions to the insurance and
                        finance sectors, including brokers, lenders and dealers.</p>
                        <p>
                        Our focus is on designing innovative technology
                        solutions to elevate business value and foster growth for
                        our partners.</p>
                        
                        `,
                    isOpen: false
                },
                {
                    id: 2,
                    q: "Who is Insurance Advisernet?",
                    a: `<p>Insurance Advisernet was founded in 1996 to provide high-quality risk management advice that business owners can trust.</p>
                        <p>Today, they are one of the largest and most respected General Insurance businesses in Australia and New Zealand, with an ever-growing network of over 270 insurance advice practices across 150 locations.</p>`,
                    isOpen: false
                },
                {
                    id: 3,
                    q: "How the Your Insurance portal works",
                    a: `<p>The Your Insurance Portal serves as a comprehensive hub for all
your insurance requirements, providing our partners with seamless
access to diverse insurance markets and products. Our
cutting-edge technology offers immediate solutions with
comprehensive insurance options for market comparison. There
are two options:.
</p>
<p>
<b>Virtual Insurance Broking Service (VIBS):</b> Our Virtual Insurance
Broking Service allows you to source multiple quotes from the
market, providing your clients with multiple options to choose from.
This service is provided by Insurance Advisernet.
</p>
<p>
<b>Light Commercial Motor:</b> Our portal provides real time quoting and
immediate policy binding for Light Commercial Motor vehicles of up to 4.5
tonnes through Underwriter, Mercurien. Vehicles in the light commercial
space include, Passenger vehicles, Utes and Vans etc.
</p>
`,
                    isOpen: false
                },
                {
                    id: 4,
                    q: "Login Page",
                    a: `<p>Sign in at https://partner.yourinsurancehq.com.au/login</p> 
                    <p>Click Remember Me so your details will be saved.</p>  
                    <p>Click Forgot Password if you do not know your login details and an email will be sent to you to reset your password.</p>
                        <p>
                            <img width="600px" class="digger" src="/img/support/login-page.png" />
                        </p>`,
                    isOpen: false
                },
                {
                    id: 5,
                    q: "Home Page",
                    a: `<p>Select which product you would like from under the links on the left hand side.</p>
                        <p>Our Virtual Broking Service covers all insurance risks. Proposal forms include:</p>
                        <ul>
                            <li>Plant and Equipment</</li>
                            <li>Motor of all sizes</li>
                            <li>Other Assets (Anything that does not fit under P&E and Motor)</li>
                            </ul>
                        <p>Get direct instantaneous binded quotes for light commercial vehicles up to 4.5. tonnes. </p> 
                        <p>Please use the VIBS link if more than one quote is requested on light motor vehicles.</p>
                        <p>The Dashboard will show Announcements and Statistics regarding your account including the number of quotes and number of policies placed.</p>
                        <p>
                            <img width="600px" class="digger" src="/img/support/home-page.png" />
                        </p>`,
                    isOpen: false
                },
                {
                    id: 6,
                    q: "Create a Quote using VIBS",
                    a: `<p>Click Create Quote link under the necessary product category to start a quote.</p>
                        <p>Depending on the asset, each form is slightly different but follows the same simple follow the bouncing ball approach.  The forms are kept as short as possible for ease of entry.</p>
                        <p>All red * asterisks are required fields.</p>
                        <p>
                            <img width="600px" class="digger" src="/img/support/quote-vibs.png" />
                        </p>`,
                    isOpen: false
                },
                {
                    id: 7,
                    q: "Send to Customer",
                    a: `<p>The Send to Customer button can be selected on the Customer Details page to send a link to your customer to fill in the quote form. </p>
                        <p>
                            <img width="600px" class="digger" src="/img/support/send-to-customer.png" />
                        </p>`,
                    isOpen: false
                },
                {
                    id: 8,
                    q: "Insurance Advisernet",
                    a: `<p>A member of the Insurance Advisernet team will be in touch to understand your insurance needs.</p>
                    <p>
                            <img width="600px" class="digger" src="/img/support/iaa.png" />
                        </p>`,
                    isOpen: false
                },
                {
                    id: 9,
                    q: "Submission Page",
                    a: `<p>Once a form is complete, you will receive confirmation an email will be sent shortly. </p>
                    <p>
                            <img width="600px" class="digger" src="/img/support/submission-thankyou.png" />
                        </p>
                    `,
                    isOpen: false
                },
                {
                    id: 10,
                    q: "Broker Email",
                    a: `<p>An email will be sent directly to you summarising the quote detail just entered.</p>
                    <p>If any changes need to be made, contact Insurance Advisernet directly at: </p>
                        <p>service@insadviser.net.au</p>
                        <p>
                            <img width="600px" class="digger" src="/img/support/broker-email.png" />
                        </p>
`,
                    isOpen: false
                },
                {
                    id: 11,
                    q: "Insurance Advisernet Email",
                    a: `<p>A separate email will automatically be sent to the Insurance Advisernet team to review the proposal form you or the insured has completed.  </p>
<p>If they have any questions, they will liaise with you directly or with the proposed insured.</p>
<p>
                            <img width="600px" class="digger" src="/img/support/iaa-email.png" />
                        </p>
`,
                    isOpen: false
                },
                {
                    id: 12,
                    q: "Quote Offered Email",
                    a: `<p>Once the Insurance Advisernet team has provided some options for consideration, you will receive an email to go into the Your Insurance Portal to review.</p>
                    <p>
                            <img width="600px" class="digger" src="/img/support/qo-email.png" />
                        </p>
                    `,
                    isOpen: false
                },
                {
                    id: 13,
                    q: "Quote Options",
                    a: `<p>You can now go into the Quotes section and see Offered beside the quote.</p>
                        <p>Click Quote Schedules to review the quote options and pricing.</p>
                        <p>Quotes that are not offered yet will stay as Requested.</p>
                        <p>Not Complete Quotes are quotes that have been started but the Submit button has not been selected.</p>
                        <p>
                            <img width="600px" class="digger" src="/img/support/quote-options1.png" />
                        </p>
                        `,
                    isOpen: false
                },
                {
                    id: 14,
                    q: "Quote Review",
                    a: `<p>By clicking on the Quotes Schedule button within the quote, you can see the options that have been presented. </p>
                        <p>Your client has also received the quote. The Insurance Advisernet team will be in touch with your client to discuss options. </p>
                        <p>
                            <img width="600px" class="digger" src="/img/support/quote-review1.png" />
                        </p>`,
                    isOpen: false
                },
                {
                    id: 15,
                    q: "Quote Confirmation",
                    a: `<p>Once the quote has been bound by Insurance Advisernet, you will receive a confirmation email.</p
                        <p>
                            <img width="600px" class="digger" src="/img/support/email-confirmation1.png" />
                        </p>`,
                    isOpen: false
                },
                {
                    id: 16,
                    q: "Policies",
                    a: `<p>The quote will now be shown under Policies with the agreed policy option.</p>
<p>All active policies for each product category will be found under each respective Policy link.</p>
                        <p>
                            <img width="600px" class="digger" src="/img/support/policy1.png" />
                        </p>`,
                    isOpen: false
                },
                {
                    id: 17,
                    q: "Reports",
                    a: `<p>By clicking the Reports link you can search, sort and filter on all your policies.</p>
                    <p>There is the option to Export your data via CSV file. </p>
                    <p>
                            <img width="600px" class="digger" src="/img/support/reports1.png" />
                        </p>`,
                    isOpen: false
                },
                {
                    id: 18,
                    q: "Support",
                    a: `<p>
                            Your Insurance<br/>
                            <a href="mailto:support@yourinsurancehq.com.au">support@yourinsurancehq.com.au</a>
                        </p>
                        <p>
                            Insurance Advisernet<br/>
                            <a href="mailto:service@insadviser.net.au">service@insadviser.net.au</a>
                        </p>`,
                    isOpen: false
                }
            ]
        };
    },
    methods: {
        toggleAnswer(_faq) {
            console.log(_faq.isOpen);

            if (_faq.isOpen == false) {
                _faq.isOpen = true;
                this.expand("answer" + _faq.id);
            } else {
                this.collapse("answer" + _faq.id);
                _faq.isOpen = false;
            }




        },
        collapse(refItem) {
            // select the answer element

            const answer = this.$refs[refItem][0];

            // set its height to its normal scroll height to expand it fully
            answer.style.height = 0;
        },
        expand(refItem) {
            // select answer element
            for (let i = 1; i <= 18; i++) {
                this.collapse("answer" + i.toString());
                this.faqItems[i-1].isOpen = false;
            }


            const answer = this.$refs[refItem][0];

            // set its height to its normal scroll height to expand it fully
            answer.style.height = answer.scrollHeight + "px";
        },
        autoExpand(item) {
            setTimeout(() => {
                this.collapse(item);
                this.expand(item);
                var element_to_scroll_to = document.getElementById("anch_" + item);
                element_to_scroll_to.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                // const target = document.getElementById(item);
                // const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
                // window.scrollTo({
                //     top: targetPosition,
                //     behavior: 'smooth'
                // });
            }, 200);

            setTimeout(() => {
                //this.collapse(item);
                //this.expand(item);
                // const target = document.getElementById(item);
                // const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
                // window.scrollTo({
                //     top: targetPosition,
                //     behavior: 'smooth'
                // });
                var element_to_scroll_to = document.getElementById("anch_" + item);
                element_to_scroll_to.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });

            }, 400);
        }
    },
    mounted() {
        //this.expand("answer1");

        console.log(this.mode);

        var currentPage = document.location.href;
        console.log(currentPage);

        if (this.mode == "modal") {
            if (currentPage.includes("/dashboard")) {

                this.autoExpand("answer5")

            } else if (currentPage.includes("/createQuote")) {

                this.autoExpand("answer6")

            } else  if (currentPage.includes("/quotes")) {

                this.autoExpand("answer13")

            } else if (currentPage.includes("/policies")) {

                this.autoExpand("answer16")

            } else {
                this.autoExpand("answer1")
            }
        } else {
            this.autoExpand("answer1")
        }
    },

    components: {
        //TextFooter
    }
};
</script>

<style lang="scss" scoped>
/* style the FAQ section */
.question {
    background: white;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0px 2px 2px 0 #88888855;
    padding: 10px 0;
    transition: transform 0.2s;
    position: relative;
    padding-left: 20px;
    color: red;
    border: 1px solid red;
}

.question:hover {
    background: white;
    color: red
}



/* styles when the question is clicked */
.question:active {
    transform: translateY(4px);
    box-shadow: none;
}

.answer {
    transition: 0.25s;
    /* smooth slide-in */
    height: 0;
    /* starts collapsed */
    overflow: hidden;
    line-height: 1.5;
    margin-bottom: 0px !important;
}



/* style the toggleIcon */
.toggleIcon {
    font-size: 1.5em;
    font-weight: bold;
    position: absolute;
    top: 18px;
    right: 20px;
    display: inline-block;
    line-height: 0.5;
    color: white;
}
</style>