<template>
  <div style="margin-top: 20px">
    <a ref="topSection" />
    <!-- <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">PE LIVE</h6>
        </b-col>
      </b-row>
    </base-header> -->
    <div class="container-fluid mt--6">
      <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form class="needs-validation" @submit.prevent="handleSubmit(firstFormSubmit)">

          <b-card class="uBind">
            <b-row>
              <h2 class="secondary-card-header">
                Your Quote
              </h2>
            </b-row>



            <b-row>
              <b-col md="6">
                <base-input name="Insured Name" label="Business Name" inputGroupClasses="uBind-input"
                  :rules="formRules.insuredName">
                  <el-select id="insuredDD" @blur="InsuredNameBlurred($event)" @visible-change="visibleChange($event)"
                    @keyup.native="setInsuredName($event)" @change="selectCustomer($event)" v-model="model.insuredName"
                    :disabled="fieldsDisabled.insuredName" :rules="formRules.insuredName" filterable
                    placeholder="Add or search previous customers" v-cancel-read-only>
                    <el-option class="style-dropdown" v-for="(option, index) in customerList" :key="index"
                      :label="option.Customer_insured_name + ' (' + option.Customer_contact_first_name + ' ' + option.Customer_contact_last_name + ')'"
                      :value="option.Customer_id">
                    </el-option>
                  </el-select>
                </base-input>
              </b-col>
              <b-col md="6"> </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <div>
                  <a class="abn-link" target="_blank" href="https://abr.business.gov.au/">Don't know the ABN?</a>
                </div>
                <base-input type="text" name="Insured ABN" label="Insured ABN" pattern="[0-9]{11}"
                  v-model="model.insuredABN" :disabled="fieldsDisabled.insuredABN" :rules="formRules.insuredABN"
                  prependIcon="fas fa-certificate" inputGroupClasses="uBind-input" />
              </b-col>
              <b-col md="6">
                <base-input label="Please describe the main Business activities" placeholder="E.g. Excavation services"
                  v-model="model.businessDesc" :disabled="fieldsDisabled.businessDesc" inputGroupClasses="uBind-input" />
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="mb-4">
            <b-card-header>
              <h3 class="mb-0">Insured Details</h3>
            </b-card-header>

            <b-card-body>
              <div v-html="certContents"></div>

              <b-row>
                <b-col md="4">
                  <base-input name="Policy Start Date" label="Policy Start Date" :rules="formRules.policyStart"
                    :disabled="fieldsDisabled.policyStart" type="date" v-model="model.policyStart" />
                </b-col>

              </b-row>

              <b-row>
                <b-col md="6">
                  <base-input name="Contact First Name" label="Contact First Name" :rules="formRules.contactFName"
                    v-model="model.contactFName" :disabled="fieldsDisabled.contactFName" placeholder="John" />
                </b-col>
                <b-col md="6">
                  <base-input name="Contact Last Name" label="Contact Last Name" :rules="formRules.contactLName"
                    v-model="model.contactLName" :disabled="fieldsDisabled.contactLName" placeholder="Smith" />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <base-input label="Contact Phone Number" name="Contact Phone Number" :rules="formRules.contactPhone"
                    v-model="model.contactPhone" :disabled="fieldsDisabled.contactPhone" />
                </b-col>
                <b-col md="6">
                  <base-input name="Contact Email" label="Contact Email" :rules="formRules.contactEmail"
                    v-model="model.contactEmail" :disabled="fieldsDisabled.contactEmail" placeholder="john@example.com" />
                </b-col>
              </b-row>
              <!--
              <b-row>
                <b-col md="12" class="mb-4">
                  <label for="addrs_1" class="form-control-label">
                    Search Insured Address
                  </label>
                  <input
                    style="cursor: text"
                    @blur="clearAddressFields"
                    class="form-control"
                    id="addrs_1"
                    v-model="model.insuredStreet"
                    :disabled="fieldsDisabled.insuredStreet"
                    :rules="formRules.insuredStreet"
                  />
                </b-col>
              </b-row>
               <b-row>
                <b-col md="4">
                  <base-input
                    label="City"
                    v-model="model.insuredCity"
                    :disabled="fieldsDisabled.insuredCity"
                    :rules="formRules.insuredCity"
                  />
                </b-col>
                <b-col md="4">
                  
                  <base-input
                    name="State"
                    label="State"
                    :rules="formRules.insuredState"
                  >
                    <el-select
                      v-model="model.insuredState"
                      :disabled="fieldsDisabled.insuredState"
                    >
                      <el-option
                        v-for="option in states"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Postcode"
                    type="number"
                    v-model="model.insuredPostcode"
                    :disabled="fieldsDisabled.insuredPostcode"
                  />
                </b-col>
              </b-row> -->
            </b-card-body>
          </b-card>

          <b-card class="uBind">
            <b-row>
              <h2 class="secondary-card-header">
                Address Details
              </h2>
            </b-row>

            <b-row>
              <b-col md="12" class="mb-4 uBind-input">
                <label for="addrs_1" class="form-control-label">
                  Search Insured Address
                </label>
                <input autocomplete="new-password" style="cursor: text" @blur="clearAddressFields" class="form-control"
                  id="addrs_1" v-model="model.insuredStreet" :disabled="fieldsDisabled.insuredStreet"
                  :rules="formRules.insuredStreet" />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4">
                <base-input label="City" v-model="model.insuredCity" :disabled="fieldsDisabled.insuredCity"
                  :rules="formRules.insuredCity" inputGroupClasses="uBind-input" />
              </b-col>
              <b-col md="4">
                <!-- <base-input label="State" v-model="model.insuredState" /> -->
                <base-input name="State" label="State" :rules="formRules.insuredState" inputGroupClasses="uBind-input">
                  <el-select v-model="model.insuredState" :disabled="fieldsDisabled.insuredState">
                    <el-option v-for="option in states" :key="option.label" :label="option.label" :value="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </b-col>
              <b-col md="4">
                <base-input autocomplete="off" label="Postcode" type="number" v-model="model.insuredPostcode"
                  :disabled="fieldsDisabled.insuredPostcode" inputGroupClasses="uBind-input" />
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="mb-4">
            <b-card-header>
              <h2 class="secondary-card-header">
                Equipment and Finance Details
              </h2>
            </b-card-header>

            <b-row>

            </b-row>

            <b-card-body>
              <b-row>
                <b-col md="12">
                  <base-input name="Equipment Description(s)" label="Equipment Description(s)"
                    :rules="formRules.equipmentDescription" placeholder="I.e. Year, Make, Model"
                    v-model="model.equipmentDescription" :disabled="fieldsDisabled.equipmentDescription" />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <base-input name="Total Equipment Value" label="Total Equipment Value" type="number"
                    prepend-icon="fas fa-dollar-sign" :rules="formRules.equipmentValue" v-model="model.equipmentValue"
                    :disabled="fieldsDisabled.equipmentValue" />
                </b-col>
                <b-col md="6">
                  <base-input name="Equipment Condition" label="Equipment Condition">
                    <el-select v-model="model.equipmentCondition" :disabled="fieldsDisabled.equipmentCondition">
                      <el-option v-for="option in equipmentConditions" :key="option.label" :label="option.label"
                        :value="option.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
                <!-- <b-col md="4">
                  <base-input
                    name="Area of Operation"
                    label="Area of Operation"
                  >
                    <el-select
                      v-model="model.operatingArea"
                      :disabled="fieldsDisabled.operatingArea"
                    >
                      <el-option
                        v-for="option in operatingAreas"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col md="6">
                  <base-input name="Equipment used on Public Road" label="Equipment used on Public Road">
                    <el-select v-model="model.usedOnPublicRoad" :disabled="fieldsDisabled.usedOnPublicRoad">
                      <el-option v-for="option in usedOnPublicRoadOptions" :key="option.label" :label="option.label"
                        :value="option.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
                <b-col md="6">
                  <base-input disabled name="Insurance Period" label="Insurance Period" v-model="model.insurancePeriod" />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <base-input name="Financier" label="Financier">
                    <el-select id="financierDD" @change="readFinancier($event)" v-model="model.financier"
                      :disabled="fieldsDisabled.financier" filterable>
                      <el-option v-for="option in financiers" :key="option.label" :label="option.label"
                        :value="option.value">
                      </el-option>
                    </el-select>
                  </base-input>
                </b-col>
                <b-col md="6">
                  <base-input id="financierOther" v-bind:disabled="financierOtherDisabled"
                    placeholder="Select 'Other' in dropdown to activate this field" name="Financier - Other"
                    label="Financier - Other" v-model="model.financierOther" />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <div class="text-center">
            <b-row align-v="center" v-if="formMode == 'create'">
              <b-col>
                <base-button @click="createNewQuote()" type="danger" class="mb-4 bg-danger">Get Quote</base-button>
              </b-col>
            </b-row>
            <div v-if="model.Customer_policy_status == 'in_progress' ||
              brokerObj.Broker_super_admin
              ">
              <b-row align-v="center" v-if="formMode == 'view'">
                <b-col>
                  <base-button @click="editQuote()" type="danger" class="mb-4 bg-danger">Edit Quote</base-button>
                </b-col>
              </b-row>

              <b-row align-v="center" v-if="formMode == 'edit'">
                <b-col>
                  <base-button @click="updateQuote()" type="danger" class="mb-4 bg-danger">Update Quote</base-button>
                </b-col>
              </b-row>
            </div>
          </div>

          <a ref="calcSection" />
          <b-card no-body class="mb-4" v-if="((mode == 'view' || mode == 'edit') &&
              model.Customer_policy_status == 'in_progress') ||
            model.Customer_policy_status ==
            'offer_sent_awaiting_information' ||
            model.Customer_policy_status == 'offer_accepted'
            ">
            <b-card-header>
              <h3 class="mb-0">Insurance Offer</h3>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col md="12">
                  <base-input prepend-icon="fas fa-dollar-sign" readonly
                    v-model="model.Customer_policy_insurance_total_premium" name="Total Insurance Premium"
                    label="Total Insurance Premium (inc all fees and charges)" />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <base-input v-model="model.Customer_policy_insurance_premium" prepend-icon="fas fa-dollar-sign" readonly
                    name="Insurance Premium" label="Insurance Premium" />
                </b-col>
                <b-col md="3">
                  <base-input v-model="model.Customer_policy_insurance_premium_gst" prepend-icon="fas fa-dollar-sign"
                    readonly name="Premium GST" label="Premium GST" />
                </b-col>
                <b-col md="3">
                  <base-input v-model="model.Customer_policy_insurance_premium_stamp_duty"
                    prepend-icon="fas fa-dollar-sign" readonly name="Stamp Duty" label="Stamp Duty" />
                </b-col>
                <b-col md="3">
                  <base-input v-model="model.Customer_policy_fee" prepend-icon="fas fa-dollar-sign" readonly
                    name="Policy Fee" label="Policy Fee" />
                </b-col>
              </b-row>
              <div class="text-center" v-if="mode == 'view' &&
                model.Customer_policy_status == 'in_progress'
                ">
                <b-row align-v="center">
                  <b-col>
                    <base-button @click="emailOffer()" type="danger" class="mb-4 bg-danger">Email Customer Insurance
                      offer</base-button>
                  </b-col>
                </b-row>
              </div>
              <div class="text-center" v-if="model.Customer_policy_status ==
                'offer_sent_awaiting_information'
                ">
                <b-row align-v="center">
                  <b-col>
                    <base-button @click="emailOffer()" type="danger" class="mb-4 bg-danger">Resend Customer Insurance
                      Offer</base-button>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import BaseInput from "../components/Inputs/BaseInput.vue";
import { Select, Option } from "element-ui";
import { dropDowns } from "../assets/data/dropdown_values";
import { quoteFormMethods } from "../helpers/quoteForm";
import { GlobalStore } from "../globalStore";
import { emailMethods } from "../helpers/email";
import { appFunctions } from "../helpers/appFunctions";

// import QuotesAndPoliciesVue from "../views/QuotesAndPolicies.vue";

export default {
  name: "QuoteForm",
  policy_id: "",
  selectedCustomerId: 0,
  props: {
    rawModel: null,
    model: {
      type: Object,
      default: {
        policyStart: "",
        insuredName: "",
        insuredABN: "",
        businessDesc: "",
        contactFName: "",
        contactLName: "",
        contactEmail: "",
        contactPhone: "",
        insuredAddress: "",
        insuredStreet: "",
        insuredCity: "",
        insuredState: null,
        insuredPostcode: "",
        equipmentDescription: "",
        equipmentValue: 0,
        equipmentCondition: "",
        operatingArea: "",
        usedOnPublicRoad: "",
        insurancePeriod: "12 Months",
        financier: "",
        financierOther: "",
        Customer_policy_insurance_total_premium: null,
        Customer_policy_insurance_premium: null,
        Customer_policy_insurance_premium_gst: null,
        Customer_policy_insurance_premium_stamp_duty: null,
        Customer_policy_fee: null,
        Customer_policy_status: null
      }
    },
    mode: {
      type: String,
      default: "editable"
    }
  },
  data() {
    return {
      certContents: "",
      realmApp: null,
      formMode: "create",
      financiers: dropDowns.financiers,
      equipmentConditions: dropDowns.equipmentConditions,
      states: dropDowns.states,
      operatingAreas: dropDowns.operatingAreas,
      usedOnPublicRoadOptions: dropDowns.usedOnPublicRoadOptions,
      validated: false,
      financierOtherDisabled: true,
      enteredInsuredName: "",
      customerList: []
    };
  },
  computed: {
    fieldsDisabled() {
      return quoteFormMethods.getDisabledFields(
        this.formMode,
        this.model.Customer_policy_status
      );
    },
    formRules() {
      return quoteFormMethods.getFormRules("in_progress");
    },
    brokerObj() {
      return GlobalStore.brokerObj;
    }
  },
  methods: {
    // selectCustomer(e) {
    //   console.log(e);
    //   //console.log(this.model.insuredName);
    //   if (e) {
    //     var findCustomer = this.customerList.find(f => f.value == e);
    //     this.enteredInsuredName = findCustomer.label.replace(
    //       / *\([^)]*\) */g,
    //       ""
    //     );
    //     this.model.insuredName = findCustomer.label.replace(
    //       / *\([^)]*\) */g,
    //       ""
    //     );

    //     console.log(findCustomer);

    //     GlobalStore.realmApp.functions
    //       .crud_customer("get", {
    //         Customer_id: parseInt(e)
    //       })
    //       .then(resp => {
    //         console.log(resp);
    //         var d = resp.data;
    //         this.model.businessDesc = d.Customer_business_description;
    //         this.model.contactEmail = d.Customer_contact_email;
    //         this.model.contactPhone = d.Customer_contact_number;
    //         this.model.insuredStreet = d.Customer_contact_street;
    //         this.model.insuredCity = d.Customer_contact_city;
    //         this.model.insuredState = d.Customer_state;
    //         this.model.insuredPostcode = d.Customer_postcode;
    //         this.model.insuredName = d.Customer_insured_name;
    //         this.model.insuredABN = d.Customer_abn;
    //         this.model.contactFName = d.Customer_contact_first_name;
    //         this.model.contactLName = d.Customer_contact_last_name;

    //         this.fieldsDisabled.businessDesc = true;
    //         this.fieldsDisabled.contactEmail = true;
    //         this.fieldsDisabled.contactPhone = true;
    //         this.fieldsDisabled.insuredStreet = true;
    //         this.fieldsDisabled.insuredCity = true;
    //         this.fieldsDisabled.insuredState = true;
    //         this.fieldsDisabled.insuredPostcode = true;
    //         //this.fieldsDisabled.insuredName = true;
    //         this.fieldsDisabled.insuredABN = true;
    //         this.fieldsDisabled.contactFName = true;
    //         this.fieldsDisabled.contactLName = true;

    //         this.selectedCustomerId = d.Customer_id;
    //       });
    //   }
    //   //   / *\([^)]*\) */g,
    //   //   ""
    //   // );
    // },
    // // InsuredNameBlurred(e) {
    // //   console.log(e);
    // //   this.model.insuredName = this.enteredInsuredName;

    // //   // if (this.model.insuredName.includes("(")) {
    // //   //   this.model.insuredName = this.model.insuredName.replace(
    // //   //     / *\([^)]*\) */g,
    // //   //     ""
    // //   //   );
    // //   // }
    // // },
    // // setInsuredName(e) {
    // //   //console.log(e);
    // //   //this.model.insuredName = e.target.value;

    // //   GlobalStore.realmApp.functions
    // //     .crud_customer("searchCustomerByRealmId", {
    // //       Realm_user_id: GlobalStore.brokerObj.Realm_user_id,
    // //       searchText: e.target.value
    // //     })
    // //     .then(resp => {
    // //       console.log(resp);
    // //       this.customerList = resp.data;
    // //     });

    // //   this.enteredInsuredName = e.target.value;
    // // },
    selectCustomer(e) {
      this.selected = true
      const findCustomer = this.customerList.find(f => f.Customer_id == e);
      this.enteredInsuredName = findCustomer ? findCustomer.Customer_insured_name : this.enteredInsuredName
      if (findCustomer) {
        this.formData(findCustomer, true)
      } else {
        this.formData(null, false)
      }
    },
    formData(data, disabled) {
      this.model.insuredName = data ? data.Customer_insured_name : this.enteredInsuredName
      this.model.businessDesc = data ? data.Customer_business_description : ""
      this.model.contactEmail = data ? data.Customer_contact_email : ""
      this.model.contactPhone = data ? data.Customer_contact_number : ""
      this.model.insuredStreet = data ? data.Customer_contact_street : ""
      this.model.insuredCity = data ? data.Customer_contact_city : ""
      this.model.insuredState = data ? data.Customer_state : ""
      this.model.insuredPostcode = data ? data.Customer_postcode : ""
      this.model.insuredABN = data ? data.Customer_abn : ""
      this.model.contactFName = data ? data.Customer_contact_first_name : ""
      this.model.contactLName = data ? data.Customer_contact_last_name : ""
      this.model.selectedCustomerId = data ? data.Customer_id : ""
      this.model.Ubind_customer_id = data ? data.Ubind_customer_id : ""
      this.fieldsDisabled.businessDesc = disabled
      this.fieldsDisabled.contactEmail = disabled
      this.fieldsDisabled.contactPhone = disabled
      this.fieldsDisabled.insuredStreet = disabled
      this.fieldsDisabled.insuredCity = disabled
      this.fieldsDisabled.insuredState = disabled
      this.fieldsDisabled.insuredPostcode = disabled
      this.fieldsDisabled.insuredABN = disabled
      this.fieldsDisabled.contactFName = disabled
      this.fieldsDisabled.contactLName = disabled
    },
    InsuredNameBlurred(e) {
      this.model.insuredName = this.enteredInsuredName;
    },
    setInsuredName(e) {
      this.enteredInsuredName = e.target.value;
    },
    visibleChange(e) {

      console.log("fired");

      if (e) {
        this.selected = false
      }
      if (!e && !this.selected) {
        this.model.insuredName = this.enteredInsuredName;
        this.enteredInsuredName && this.formData(null, false)
      }
    },
    scrollUp() {
      //location.hash = "#calcSection";
      const myEl = this.$refs.topSection;

      this.$smoothScroll({
        scrollTo: myEl,
        hash: "#topSection" // required if updateHistory is true
      });
    },
    scrollDown() {
      //location.hash = "#calcSection";
      const myEl = this.$refs.calcSection;

      this.$smoothScroll({
        scrollTo: myEl,
        hash: "#calcSection" // required if updateHistory is true
      });
    },
    async createNewQuote() {
      const isValid = await this.$refs.formValidator.validate();

      if (!isValid) {
        this.$toasted
          .error("Please complete the required fields before proceeding.", {
            position: "bottom-center"
          })
          .goAway(4000);
      } else {
        var calcData = quoteFormMethods.getCalculation(this.model);

        this.model.Customer_policy_insurance_total_premium =
          calcData.Customer_policy_insurance_total_premium;
        this.model.Customer_policy_insurance_premium =
          calcData.Customer_policy_insurance_premium;
        this.model.Customer_policy_insurance_premium_gst =
          calcData.Customer_policy_insurance_premium_gst;
        this.model.Customer_policy_insurance_premium_stamp_duty =
          calcData.Customer_policy_insurance_premium_stamp_duty;
        this.model.Customer_policy_fee = calcData.Customer_policy_fee;

        this.$toasted
          .success("Quote Successfully Created", {
            position: "bottom-center"
          })
          .goAway(4000);

        //

        this.mode = "view";

        this.model.Customer_policy_status = "in_progress";

        //console.log(this.selectedCustomerId);

        // var policy_id = await quoteFormMethods.createQuote(
        //   this.model,
        //   this.selectedCustomerId
        // );

        // this.$router.push({
        //   path: "quotes/" + policy_id,
        //   query: { status: "created" }
        // });
      }
    },
    emailOffer() {
      // quoteFormMethods.changeStatus(
      //   this.policy_id,
      //   "offer_sent_awaiting_information"
      // );

      // GlobalStore.realmApp.functions
      //   .crud_customerPolicy("getCombined", {
      //     policy_id: parseInt(this.policy_id)
      //   })
      //   .then(resp => {
      //     emailMethods.sendOfferEmail(GlobalStore.realmApp, resp.data);
      //     this.$router.push("/quotes/" + this.policy_id + "/offerSent");
      //   });
    },
    editQuote() {
      this.formMode = "edit";
      this.scrollUp();
    },
    updateQuote() {
      var calcData = quoteFormMethods.getCalculation(this.model);

      this.model.Customer_policy_insurance_total_premium =
        calcData.Customer_policy_insurance_total_premium;
      this.model.Customer_policy_insurance_premium =
        calcData.Customer_policy_insurance_premium;
      this.model.Customer_policy_insurance_premium_gst =
        calcData.Customer_policy_insurance_premium_gst;
      this.model.Customer_policy_insurance_premium_stamp_duty =
        calcData.Customer_policy_insurance_premium_stamp_duty;
      this.model.Customer_policy_fee = calcData.Customer_policy_fee;

      quoteFormMethods.updateQuote(this.realmApp, this.rawModel, this.model);

      this.scrollDown();

      this.formMode = "view";
    },
    firstFormSubmit() {
      console.log("clicked");
    },
    readFinancier(event) {
      console.log("typed");
      if (event == "Other") {
        this.financierOtherDisabled = false;
        //this.model.financier = "";
      } else {
        this.financierOtherDisabled = true;
        this.model.financierOther = "";
      }
    },
    /**
     * @desc Clears the address city, state and postcode fields when the street
     * field is cleared.
     */
    clearAddressFields() {
      if (this.model.insuredStreet == "") {
        this.model.insuredCity = "";
        this.model.insuredState = "";
        this.model.insuredPostcode = "";
      }
    }
  },
  async mounted() {

    await appFunctions.crud_customerLCPolicy("getCombinedByRealmId", {
            Realm_user_id: GlobalStore.brokerObj.Realm_user_id
          })
          .then(resp => {
            this.customerList = resp.data.filter(data => data && data.Customer_insured_name)
          });


    this.fieldsDisabled.businessDesc = false;
    this.fieldsDisabled.contactEmail = false;
    this.fieldsDisabled.contactPhone = false;
    this.fieldsDisabled.insuredStreet = false;
    this.fieldsDisabled.insuredCity = false;
    this.fieldsDisabled.insuredState = false;
    this.fieldsDisabled.insuredPostcode = false;
    //this.fieldsDisabled.insuredName = true;
    this.fieldsDisabled.insuredABN = false;
    this.fieldsDisabled.contactFName = false;
    this.fieldsDisabled.contactLName = false;

    this.fieldsDisabled.policyStart = false;

    this.fieldsDisabled.insuredName = false;

    // this.formMode = this.mode;
    // this.realmApp = GlobalStore.realmApp;

    // this.policy_id = this.$route.params.id;

    // this.selectedCustomerId = 0;

    // //changeStatus();

    // let script = document.createElement("script");
    // script.setAttribute(
    //   "src",
    //   "https://api.addressfinder.io/assets/v3/widget.js"
    // );
    // script.setAttribute("async", true);
    // script.onload = () => {
    //   this.widget = new window.AddressFinder.Widget(
    //     document.getElementById("addrs_1"),
    //     "NMY9RTVQ3UHADL76FJ8G",
    //     "AU",
    //     {
    //       address_params: {
    //         gnaf: 1
    //       }
    //     }
    //   );
    //   this.widget.on("result:select", (fullAddress, metaData) => {
    //     this.model.insuredAddress = metaData.address_line_1;
    //     this.model.insuredStreet = metaData.address_line_1;
    //     this.model.insuredCity = metaData.locality_name;
    //     this.model.insuredState = metaData.state_territory;
    //     this.model.insuredPostcode = metaData.postcode;
    //   });
    // };
    // document.head.appendChild(script);

    // console.log(this.$route.params.status);

    // if (this.$route.query.status) {
    //   this.scrollDown();
    // }
  },
  components: {
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option
  }
};
</script>
<style>
.abn-link {
  font-size: 9pt;
  color: var(--uBind) !important;
  position: absolute;
  right: 0px;
  margin-right: 10px;
}
</style>
